<template>
  <b-tabs
    pills
    nav-class="nav-left"
    nav-wrapper-class="fullWidth"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Information</span>
      </template>

      <Profile style="width:100%;max-width: 1000px" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <Password style="width:100%;max-width: 1000px" />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Profile from './Profile.vue'
import Password from './Password.vue'

export default {
  components: {
    BTabs,
    BTab,
    Profile,
    Password,
  },
  data() {
    return {
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>

<style lang="scss">
@media (max-width: 767px) {
  .fullWidth{
    .nav-item {
      width: 100%;
    }
  }
}
</style>
